<template>
  <div style="width: 100%">
    <div style="width: 100%">
      <v-radio-group v-model="type_element" :mandatory="false" row>
        <v-radio label="File âm thanh" value="Audio"></v-radio>
        <v-radio label="Video (Vimeo)" value="Video"></v-radio>
        <v-radio
          label="Embed Youtube"
          value="Embed_yt"
          v-if="element_form_display === 'element'"
        ></v-radio>
        <v-radio label="Ảnh" value="Image"></v-radio>
        <v-radio label="Văn bản" value="Post"></v-radio>
        <v-radio label="Pdf" value="Pdf"></v-radio>
        <v-radio
          label="Album"
          value="Album"
          v-if="element_form_display === 'element'"
        ></v-radio>
        <v-radio label="Flashcard" value="Flashcard" v-if="hasAccessConfigFlashcard"></v-radio>
      </v-radio-group>
    </div>
    <div id="containerElementData" style="width: 100%">
      <!-- <v-file-input small-chips label="Chọn tệp tin" @click="checkClickFile" id="filechose_button" @change="onElementChange" v-if="type_element != null && type_element != 'Embed_yt' && type_element != 'Post' && type_element != 'Video'" :accept="checkInputAcceptAttribute(type_element)"></v-file-input> -->
      <b-form-file
        @change="onElementChange"
        placeholder="Chọn tệp tin hoặc cancel để xóa"
        :accept="checkInputAcceptAttribute(type_element)"
        v-if="type_element === 'Audio' || type_element === 'Pdf'"
      ></b-form-file>
      <template v-if="type_element === 'Image'">
        <ImageCompression
          :done="getFiles"
        ></ImageCompression>
      </template>
      <div class="d-flex align-center" v-if="type_element === 'Embed_yt'">
        <label class="text-h6 mb-7">Link:</label>
        <v-text-field
          solo
          dense
          class="ml-2"
          v-model="element_youtube_data"
        ></v-text-field>
      </div>

      <div class="mt-2">
        <vuetify-audio
          v-if="type_element === 'Audio'"
          :file="element_audio_data"
          color="success"
        ></vuetify-audio>
        <v-img
          v-if="type_element === 'Image' && element_image_data !== null"
          :src="element_image_data"
        ></v-img>
        <div v-if="type_element === 'Video'">
          <div class="d-inline">
            <template v-for="(file, i) in file_videos">
              <div
                :key="i"
                style="width: 50%; cursor: pointer"
                class="d-inline-block"
                @click="chooseVideo(file, 'section')"
              >
                <v-icon style="width: 10%" class="d-inline-block" x-large
                  >mdi-folder</v-icon
                >
                <v-text-field
                  style="width: 85%"
                  class="ml-2 d-inline-block"
                  :label="i"
                  solo
                  disabled
                ></v-text-field>
              </div>
            </template>
          </div>
          <v-col cols="12">
            <!--            vimeo-->
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="video_element_data"
            ></b-embed>

            <!--            muse-->
            <!--            <b-embed-->
            <!--              type="iframe"-->
            <!--              aspect="16by9"-->
            <!--              :src="'https://muse.ai/embed/'+ video_element_data+'?'"-->
            <!--            ></b-embed>-->
          </v-col>
        </div>
        <div v-if="element_pdf_data !== null || element_pdf_data !== undefined">
          <b-embed
            v-if="type_element === 'Pdf'"
            type="iframe"
            aspect="16by9"
            :src="element_pdf_data"
          ></b-embed>
        </div>
      </div>
      <!--      <youtube-->
      <!--          :width="widthYoutube"-->
      <!--          :video-id="convertUrlToIdYt(element_youtube_data)" ref="youtube" id="youtube"-->
      <!--          v-if="type_element === 'Embed_yt'"-->
      <!--      ></youtube>-->
      <template v-if="element_youtube_data">
        <b-embed
          v-if="type_element === 'Embed_yt'"
          type="iframe"
          aspect="16by9"
          :src="
            'https://www.youtube.com/embed/' +
            convertUrlToIdYt(element_youtube_data)
          "
        >
        </b-embed>
      </template>
      <Editor
        v-if="type_element === 'Post'"
        v-model="element_post_data"
      ></Editor>
      <template v-if="type_element === 'Album'">
        <vue-dropzone
          style="width: 100%"
          ref="imgDropZone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-complete="afterComplete"
        >
        </vue-dropzone>
        <v-row v-if="album_element_data.length" class="mt-2">
          <draggable
            :list="album_element_data"
            @change="sort_album"
            class="row"
          >
            <template v-for="(photo, index) in album_element_data">
              <v-col cols="3" :key="index" class="hover-photo">
                <v-icon
                  class="float-right item-photo"
                  color="red"
                  @click="deletePhoto(photo, index)"
                  >mdi-close</v-icon
                >
                <v-img contain :src="photo.url"></v-img>
              </v-col>
            </template>
          </draggable>
        </v-row>
      </template>
      <div class="full-width d-block" v-if="type_element === 'Image' && hasInputDescriptionImage">
        <div class="mt-4 mb-4 d-block" style="width: 100%" v-if="hasConfigGetDescriptionImageByAi">
          <span class="text-h6 float-left font-weight-medium">Mô tả ảnh</span>
          <span
              @click="getDescriptionFromImageFile()"
              class="text-h6 float-right font-weight-bold blue--text cursor-pointer"
          >Xem gợi ý</span>
        </div>
        <v-textarea
            v-model="element_description"
            style="width: 100%"
            placeholder="Nhập mô tả ảnh"
            outlined dense
        ></v-textarea>
      </div>
      <template v-if="hasConfigSlowSound && type_element === 'Audio'">
        <v-checkbox
            v-model="element_has_slow_sound"
            label="Có slow sound"
        ></v-checkbox>
      </template>
      <template v-if="type_element === 'Flashcard'">
        <Flashcard />
      </template>
    </div>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import { mapState, mapGetters } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import { getIdFromUrl } from "vue-youtube";
import image_link_to_file from "../../../../mixins/image_link_to_file";
import configPart from "./Support/Section/configPart";
import productCategorySupport from "./Support/Test/productCategorySupport";
export default {
  name: "ElementChoose",
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
    vueDropzone: vue2Dropzone,
    draggable: draggable,
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
    ImageCompression: () => import("@/components/image/ImageCompress"),
    Flashcard: () => import("./Element/Flashcard"),
  },
  mixins: [
      image_link_to_file,
      configPart,
      productCategorySupport,
  ],
  props: {
    hasInputDescriptionImage: {
      type: Boolean,
      default: false
    },
    hasConfigSlowSound: {
      type: Boolean,
      default: false
    },
    hasConfigGetDescriptionImageByAi: {
      type: Boolean,
      default: false
    },
    hasAccessConfigFlashcard: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      file_videos: [],
      hoverPhoto: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        acceptedFiles: ".jpg, .jpeg, .png",
        addRemoveLinks: false,
        maxFilesize: 102400000,
      },
      album_photo: [],
      widthYoutube: 300,
      skillCurrent: null,
    };
  },
  computed: {
    ...mapState({
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      dialogQuestion: (state) => state.contentTestBuilderStore.dialogQuestion,
      dialogQuestionIeltsVWRPart1: (state) => state.contentTestBuilderStore.dialogQuestionIeltsVWRPart1,
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    type_element: {
      get() {
        return this.$store.state.elementStore.type_element;
      },
      set(value) {
        this.$store.commit("elementStore/setTypeElement", value);
      },
    },
    element_post_data: {
      get() {
        return this.$store.state.elementStore.element_post_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPostData", value);
      },
    },
    element_audio_data: {
      get() {
        return this.$store.state.elementStore.element_audio_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementAudioData", value);
      },
    },
    element_image_data: {
      get() {
        return this.$store.state.elementStore.element_image_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementImageData", value);
      },
    },
    element_audio_file: {
      get() {
        return this.$store.state.elementStore.element_audio_file;
      },
      set(value) {
        this.$store.commit("elementStore/setElementAudioFile", value);
      },
    },
    element_image_file: {
      get() {
        return this.$store.state.elementStore.element_image_file;
      },
      set(value) {
        this.$store.commit("elementStore/setElementImageFile", value);
      },
    },
    element_pdf_file: {
      get() {
        return this.$store.state.elementStore.element_pdf_file;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPdfFile", value);
      },
    },
    element_pdf_data: {
      get() {
        return this.$store.state.elementStore.element_pdf_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementPdfData", value);
      },
    },
    element_has_slow_sound: {
      get() {
        return this.$store.state.elementStore.element_has_slow_sound;
      },
      set(value) {
        this.$store.commit("elementStore/setElementHasSlowSound", value);
      },
    },
    element_id: {
      get() {
        return this.$store.state.elementStore.element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setElementId", value);
      },
    },
    videos: {
      get() {
        return this.$store.state.elementStore.videos;
      },
      set(value) {
        this.$store.commit("elementStore/setVideos", value);
      },
    },
    dialogChooseVideo: {
      get() {
        return this.$store.state.elementStore.dialogChooseVideo;
      },
      set(value) {
        this.$store.commit("elementStore/setDialogChooseVideo", value
        );
      },
    },
    type_add_video: {
      get() {
        return this.$store.state.elementStore.type_add_video;
      },
      set(value) {
        this.$store.commit("elementStore/setTypeAddVideo", value);
      },
    },
    video_element_data: {
      get() {
        return this.$store.state.elementStore.video_element_data;
      },
      set(value) {
        this.$store.commit("elementStore/setVideoElementData", value);
      },
    },
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    album_element_id: {
      get() {
        return this.$store.state.elementStore.album_element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setAlbumElementId", value);
      },
    },
    album_element_data: {
      get() {
        return this.$store.state.elementStore.album_element_data;
      },
      set(value) {
        this.$store.commit("elementStore/setAlbumElementData", value);
      },
    },
    element_form_display: {
      get() {
        return this.$store.state.elementStore.element_form_display;
      },
      set(value) {
        this.$store.commit("elementStore/setElementFormDisplay", value);
      },
    },
    element_youtube_data: {
      get() {
        return this.$store.state.elementStore.element_youtube_data;
      },
      set(value) {
        this.$store.commit("elementStore/setElementYoutube", value);
      },
    },
    element_description: {
      get() {
        return this.$store.state.elementStore.element_description;
      },
      set(value) {
        this.$store.commit("elementStore/setElementDescription", value);
      },
    },
  },
  watch: {
    type_element(val) {
      if (val === "Album") {
        if (this.album_element_id == null) {
          this.createdElementTypeAlbum();
        }
      }
      if (val === "Flashcard") {
        if (this.$utils.isValueInvalid(this.element_id)) {
          this.createdElementTypeFlashcard();
        }
      }
    },
    // element_youtube_data() {
    //   this.setWidthYoutube();
    // }
  },
  created() {
    this.getAllVideoForElement();
  },
  mounted() {
    // this.setWidthYoutube();
  },
  methods: {
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    sort_album() {
      let data_sort = [...this.album_element_data];
      for (let index = 0; index < data_sort.length; index++) {
        data_sort[index].offset = index + 1;
      }
      let vm = this;
      vm.is_call_api = true;
      ApiService.put(
        "prep-app/element/album/" + vm.album_element_id + "/sort",
        { photos: data_sort }
      )
        .then(function (res) {
          if (res.status === 202) {
            vm.album_element_data = data_sort;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error) {
            vm.is_call_api = false;
            vm.messError("Something Wrong. Please try again or contact admin");
          }
        });
    },
    createdElementTypeAlbum() {
      let vm = this;
      vm.is_call_api = true;
      ApiService.post("prep-app/element/album")
        .then(function (res) {
          if (res.status === 200) {
            vm.album_element_id = res.data.id;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error) {
            vm.is_call_api = false;
            vm.messError("Something Wrong. Please try again or contact admin");
          }
        });
    },
    async afterComplete(file) {
      let vm = this;
      try {
        let formData = new FormData();
        formData.append("file", file);

        let res = await ApiService.post(
          "prep-app/element/album/" + vm.album_element_id + "/photo",
          formData
        );
        if (res.status === 200) {
          vm.album_element_data.push(res.data);
          vm.messError('Upload ảnh thành công');
        }
      } catch (error) {
        if (error) {
          vm.messError("Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ",);
        }
      }
      this.$refs.imgDropZone.removeFile(file);
    },
    deletePhoto(photo, indexPhoto) {
      let vm = this;
      Swal.fire({
        title: "Bạn có chắc chắc muốn xóa ảnh này?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Xác nhận",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete("prep-app/element/album/" + vm.album_element_id + "/photo/" + photo.id)
            .then(function (res) {
              if (res.status === 202) {
                vm.album_element_data.splice(indexPhoto, 1);
                vm.messError("Xóa ảnh thành công");
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              if (error) {
                vm.is_call_api = false;
                vm.messError("Thao tác không thành công. Vui lòng liên hệ phòng kỹ thuật hỗ trợ");
              }
            });
        }
      });
    },
    checkClickFile(event) {
      event = event || window.event;
      if (event.target.id !== "filechose_button") {
        return;
      }
    },
    checkInputAcceptAttribute(type) {
      let accept = "";
      if (type === "Image") {
        accept = "image/*";
      } else if (type === "Audio") {
        accept = "audio/*";
      } else if (type === "Pdf") {
        accept = "application/pdf";
      }
      return accept;
    },
    onElementChange(e) {
      let file = e.target.files[0];
      let vm = this;
      if (file === undefined) {
        if (vm.type_element === "Audio") {
          vm.element_audio_data = null;
          vm.element_audio_file = null;
        } else if (vm.type_element === "Pdf") {
          vm.element_pdf_data = null;
          vm.element_pdf_file = null;
        }
        return;
      }
      if (vm.type_element === "Audio") {
        vm.element_audio_file = file;
      } else if (vm.type_element === "Pdf") {
        vm.element_pdf_file = file;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        if (vm.type_element === "Audio") {
          vm.element_audio_data = e.target.result;
        } else if (vm.type_element === "Pdf") {
          vm.element_pdf_data = e.target.result;
        }
        vm.element_data = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    chooseVideo(videos, type) {
      this.dialogChooseVideo = true;
      this.videos = videos;
      this.type_add_video = type;
      this.video_element_data = null;
    },
    async getAllVideoForElement() {
      let vm = this;
      await ApiService.get("prep-app/element/videos")
        // await ApiService.get('prep-app/element/videos/provider-muse')
        .then(function (response) {
          if (response.status === 200) {
            vm.file_videos = response.data;
          }
        });
    },
    getFiles(obj) {
      this.element_image_data = obj.blob;
      this.element_image_file = obj.file;
    },
    setWidthYoutube() {
      let widthYoutube = document.getElementById(
        "containerElementData"
      ).offsetWidth;
      document.getElementById("youtube").style.width = widthYoutube + "px";
    },
    convertUrlToIdYt(url) {
      let id = getIdFromUrl(url);
      return id;
    },
    validateGetDescriptionImage() {
      let flat = true;
      if (!this.element_image_file && !this.element_image_data) {
        this.$toasted.error("Hãy upload image file mới để lấy gợi ý", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      if (this.dialogQuestion || this.dialogQuestionIeltsVWRPart1) {
        let partConfig = this.skills[this.indexSkill].sections[this.indexSection].partConfig;
        this.skillCurrent = this.skills[this.indexSkill].skill_type;

        if(!partConfig) {
          this.$toasted.error("Không có cấu hình part để dùng tính năng này", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        } else {
          if (!this.checkCanUseGetDesImageByAI()) {
            this.$toasted.error("Hệ thống không hỗ trợ xem gợi ý ảnh cho Part này", {theme: "toasted-primary", position: "top-right", duration: 4000,});
            flat = false;
          }
        }

      }
      return flat;
    },
    async getDescriptionFromImageFile() {
      let validate = this.validateGetDescriptionImage();
      if (!validate) {
        return;
      }

      let vm = this;

      let formData = new FormData();

      if (this.dialogQuestion) {
        let partConfig = null;
        if (this.skills[this.indexSkill].sections[this.indexSection].partConfig) {
          partConfig = this.skills[this.indexSkill].sections[this.indexSection].partConfig;
        }
        formData.append("part_config_id", partConfig.id);
      }

      if (!this.element_image_file) {
        let file = await this.imageLinkToFile(this.element_image_data)
        formData.append("file", file);
      } else {
        formData.append("file", this.element_image_file);
      }

      vm.is_call_api = true;
      await ApiService.post("prep-app/test/draft/element/get-description-from-image-element", formData)
      .then(function (res) {
        if (res.status === 200) {
          if (!res.data) {
            vm.$toasted.error("Đã có lỗi xảy ra trong quá trình lấy gợi ý. Vui lòng thử lại sau! ", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          }
          vm.element_description = res.data;
          vm.is_call_api = false;
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          vm.$toasted.error(error.response.data.error.message, {theme: "toasted-primary", position: "top-right", duration: 4000,});
        } else if(error) {
          vm.$toasted.error("Something Wrong. Please try again or contact admin !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        }
        vm.is_call_api = false;
      });
    },
    createdElementTypeFlashcard() {
      let vm = this;
      vm.is_call_api = true;
      ApiService.post("prep-app/element/flashcard")
        .then(function (res) {
          if (res.status === 200) {
            vm.element_id = res.data.id;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error) {
            vm.is_call_api = false;
            vm.messError("Something Wrong. Please try again or contact admin");
          }
        });
    },
  },
};
</script>

<style lang="css" scoped>
.item-photo {
  display: none;
}
.hover-photo:hover .item-photo {
  display: block;
}
</style>
